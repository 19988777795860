/* eslint-disable react/jsx-indent */
import React from 'react'
import loadable from '@loadable/component'

enum ParagraphType {
  AWARD_LISTING = 'paragraph__awards_listing',
  CAREER_PATHWAY_LISTING = 'paragraph__career_pathways_listing',
  COMMUNITIES_LISTING = 'paragraph__communities_listing',
  PROGRAMS_LISTING = 'paragraph__programs_listing',
  SPECIALTIES_LISTING = 'paragraph__specialties_listing',
  SEARCHABLE_SCHOOLS_LISTING = 'paragraph__searchable_schools_listing',
  HERO = 'paragraph__hero',
  CUSTOM_HTML = 'paragraph__custom_html',
  SEARCH_CTA = 'paragraph__search_cta',
  BLOG_LISTING = 'paragraph__blog_listing',
  FAQ = 'paragraph__faq',
  HEADER = 'paragraph__header',
  MARKETING_HERO = 'paragraph__marketing_hero',
  School_Map = 'paragraph__school_map',
  SCHOOL_LISTING_BY_WORLD_LANGUAGE = 'paragraph__school_listing_by_world_language',
  FIND_MY_SCHOOL_WIZARD = 'paragraph__find_my_school_wizard',
  SEARCH_CTA_CARD = 'paragraph__search_cta_card',
}

const ParagraphComponent = {
  [ParagraphType.AWARD_LISTING]: loadable(() => import('./ParagraphAwardListing')),
  [ParagraphType.CAREER_PATHWAY_LISTING]: loadable(() => import('./ParagraphCareerPathwayListing')),
  [ParagraphType.COMMUNITIES_LISTING]: loadable(() => import('./ParagraphCommunityListing')),
  [ParagraphType.PROGRAMS_LISTING]: loadable(() => import('./ParagraphProgramListing')),
  [ParagraphType.SPECIALTIES_LISTING]: loadable(() => import('./ParagraphSpecialtyListing')),
  [ParagraphType.SEARCHABLE_SCHOOLS_LISTING]: loadable(
    () => import('./ParagraphSearchableSchoolListing'),
  ),
  [ParagraphType.HERO]: loadable(() => import('./ParagraphHero')),
  [ParagraphType.CUSTOM_HTML]: loadable(() => import('./ParagraphCustomHTML')),
  [ParagraphType.SEARCH_CTA]: loadable(() => import('./ParagraphSearchCta')),
  [ParagraphType.BLOG_LISTING]: loadable(() => import('./ParagraphBlogListing')),
  [ParagraphType.FAQ]: loadable(() => import('./ParagraphFaq')),
  [ParagraphType.HEADER]: loadable(() => import('./ParagraphHeader')),
  [ParagraphType.MARKETING_HERO]: loadable(() => import('./ParagraphMarketingHero')),
  [ParagraphType.School_Map]: loadable(() => import('./ParagraphSchoolMap')),
  [ParagraphType.SCHOOL_LISTING_BY_WORLD_LANGUAGE]: loadable(
    () => import('./ParagraphSchoolListingByWorldLanguage'),
  ),
  [ParagraphType.FIND_MY_SCHOOL_WIZARD]: loadable(() => import('./ParagraphFindMySchoolWizard')),
  [ParagraphType.SEARCH_CTA_CARD]: () => <></>,
}

// eslint-disable-next-line max-len
export type ParagraphProps = { id: string; __typename: string }

const Paragraph = ({ __typename: typename, ...rest }: ParagraphProps) => {
  const Component = ParagraphComponent[typename as ParagraphType] as React.FC<any>

  // Component is not defined for this paragraph type.
  if (!Component) return null

  return <Component {...rest} />
}

type ParagraphsProps = {
  paragraphs: { id: string; __typename: string }[]
}

const Paragraphs: React.FC<ParagraphsProps> = ({ paragraphs }: ParagraphsProps) => (
  <div>
    {paragraphs.map((paragraph) => {
      if (!paragraph) return null

      const { id: paragraphId, __typename: typename, ...rest } = paragraph

      if (!paragraphId || !typename) return null

      return (
        <React.Fragment key={paragraphId}>
          <Paragraph id="" __typename={typename} {...rest} />
          <div className="clear-both pt-12" />
        </React.Fragment>
      )
    })}
  </div>
)

export default Paragraphs
