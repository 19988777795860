import React from 'react'
import { graphql } from 'gatsby'
import Paragraphs from '../../component/Paragraphs'
import Layout from '../../component/Layout'
import SEO from '../../component/SEO'
import { PageDetailQueryQuery } from '../../types/generated'
import { LanguageCode } from '../../types/LanguageCode'
import { getSeoImage } from '../../utils'

interface PageProps {
  data: PageDetailQueryQuery
}

const Page: React.FC<PageProps> = ({ data }: PageProps) => {
  const {
    footerMenu,
    headerMenu,
    socialMenu,
    englishPage,
    spanishPage,
    somaliPage,
    vietnamesePage,
    filipinoPage,
  } = data
  const translationPaths = {
    en: englishPage?.path?.alias,
    es: spanishPage?.path?.alias,
    so: somaliPage?.path?.alias,
    vi: vietnamesePage?.path?.alias,
    fil: filipinoPage?.path?.alias,
  }

  const menu = {
    header: headerMenu.nodes,
    footer: footerMenu.nodes,
    social: socialMenu.nodes,
  }

  return (
    <Layout
      menu={menu}
      translationPaths={translationPaths}
      langcode={data?.nodePage?.langcode as LanguageCode}
    >
      <SEO
        title={data?.nodePage?.title}
        image={getSeoImage(data?.nodePage?.relationships?.field_seo_image)}
        description={`San Diego Unified School District - ${data?.nodePage?.title}`}
      />
      <Paragraphs paragraphs={data?.nodePage?.relationships?.field_paragraph as any} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageDetailQuery($id: String!, $langcode: String!, $nid: Int!) {
    ...HeaderMenu
    ...FooterMenu
    ...SocialMenu

    nodePage(id: { eq: $id }) {
      title
      langcode
      relationships {
        field_seo_image {
          relationships {
            field_media_image {
              uri {
                value
              }
            }
          }
        }
        field_paragraph {
          ... on paragraph__hero {
            ...ParagraphHeroFields
          }

          ... on paragraph__faq {
            ...ParagraphFaqFields
          }

          ... on paragraph__search_cta {
            ...ParagraphSearchCtaFields
          }

          ... on paragraph__awards_listing {
            ...ParagraphAwardsListingFields
          }

          ... on paragraph__school_map {
            ...ParagraphSchoolMapFields
          }

          ... on paragraph__find_my_school_wizard {
            ...ParagraphFindMySchoolWizardFields
          }

          ... on paragraph__header {
            ...ParagraphHeaderFields
          }

          ... on paragraph__marketing_hero {
            ...ParagraphMarketingHeroFields
          }

          ... on paragraph__career_pathways_listing {
            ...ParagraphCareerPathwaysListingFields
          }

          ... on paragraph__communities_listing {
            ...ParagraphCommunitiesListingFields
          }

          ... on paragraph__programs_listing {
            ...ParagraphProgramsListingFields
          }

          ... on paragraph__specialties_listing {
            ...ParagraphSpecialtiesListingFields
          }

          ... on paragraph__blog_listing {
            ...ParagraphBlogListingFields
          }

          ... on paragraph__searchable_schools_listing {
            ...ParagraphSearchableSchoolsListingFields
          }

          ... on paragraph__custom_html {
            ...ParagraphCustomHtmlFields
          }
        }
      }
    }

    englishPage: nodePage(drupal_internal__nid: { eq: $nid }, langcode: { eq: "en" }) {
      path {
        alias
      }
    }

    spanishPage: nodePage(drupal_internal__nid: { eq: $nid }, langcode: { eq: "es" }) {
      path {
        alias
      }
    }

    somaliPage: nodePage(drupal_internal__nid: { eq: $nid }, langcode: { eq: "so" }) {
      path {
        alias
      }
    }

    vietnamesePage: nodePage(drupal_internal__nid: { eq: $nid }, langcode: { eq: "vi" }) {
      path {
        alias
      }
    }

    filipinoPage: nodePage(drupal_internal__nid: { eq: $nid }, langcode: { eq: "fil" }) {
      path {
        alias
      }
    }
  }
`

export default Page
